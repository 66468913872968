import React from "react";
import FrontPanel from "./components/front_panel";

export default class Site extends React.Component {
    render() {
      return (
      <div className="site">
          <FrontPanel/>
      </div>);
    }
}