import React from "react";

export default class FrontPanel extends React.Component {

    pickRandomItem() {
        const tags = [
            "We just been playin' some games, everyone's pickin' a straw",
            "My essence becomes the mystery",
            "We just erase heroes, I Stan Lee ya Remini, he the King of Queens",
            "I carry greatness, statements on spaceship",
            "I can't fall off, I got a great grip",
            "For me to fake humble's a corny way to be arrogant",
            "Running away is easy, it's the leaving that's hard"
        ]
        return tags[Math.floor(Math.random()*tags.length)];
    }

    render() {
      return (
      <div className="panel">
          <h1>Hello, the name's Tristan.</h1>
          <p className="footer-text">{this.pickRandomItem()}</p>
          <br/>
          <p><b>I'm a software developer with experience in industries across the spectrum, anything from energy companies, movie studios to gaming. I'm currently not looking for work.</b></p>
      </div>);
    }
}
